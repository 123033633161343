export default {
  data() {
      return {
         portfolioItems:  [
            {
                id: 30,
                img: require(`@/assets/images/aparts/AP_no_2/Villa_sol_oswiecim_app_two_salon_c.jpg`),
                title:'Villa Sol Oswiecim App Two - Salon',
            },
            {
                id: 31,
                img: require(`@/assets/images/aparts/AP_no_2/Villa_sol_oswiecim_app_two_salon_b.jpg`),
                title:'Villa Sol Oswiecim App Two - Salon',
            },
            {
                id: 32,
                img: require(`@/assets/images/aparts/AP_no_2/Villa_sol_oswiecim_app_two_salon.jpg`),
                title:'Villa Sol Oswiecim App Two - Salon',
            },
            {
                id: 33,
                img: require(`@/assets/images/aparts/AP_no_2/Villa_sol_oswiecim_app_two_salon_kuchnia.jpg`),
                title:'Villa Sol Oswiecim App Two - Salon i kuchnia',
            },
            {
                id: 34,
                img: require(`@/assets/images/aparts/AP_no_2/Villa_sol_oswiecim_app_two_kuchnia.jpg`),
                title:'Villa Sol Oswiecim App Two - Kuchnia',
            },
            {
                id: 35,
                img: require(`@/assets/images/aparts/AP_no_2/Villa_sol_oswiecim_app_two_lazienka.jpg`),
                title:'Villa Sol Oswiecim App Two - Łazienka',
            },
            {
                id: 36,
                img: require(`@/assets/images/aparts/AP_no_2/Villa_sol_oswiecim_app_two_sypialnia.jpg`),
                title:'Villa Sol Oswiecim App Two - Sypialnia',
            },
         ]
      }
  }
}