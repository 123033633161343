<template>
<div class="col-xl-6 col-lg-10 col-md-9 m-auto">
  <div class="container mt-40 mb-20">
    <div class="row">
      <div class="col">
        <ul class="list-group ">
        <li>
          {{ $t('apartamentFacilitiesSPA[0]')}}
        </li>
        <li>
          {{ $t('apartamentFacilitiesSPA[1]')}}
        </li>
        <li>
          {{ $t('apartamentFacilitiesSPA[2]')}}
        </li>
        <li>
          {{ $t('apartamentFacilitiesSPA[3]')}}
        </li>
        <li>
          {{ $t('apartamentFacilitiesSPA[4]')}}
        </li>
        <li>
          {{ $t('apartamentFacilitiesSPA[5]')}}
        </li>
        <li>
          {{ $t('apartamentFacilitiesSPA[6]')}}
        </li>
      </ul>
      </div>
      <div class="col">
        <ul class="list-group list-group-flush">
          <li>
            {{ $t('apartamentFacilitiesSPA[7]')}}
          </li>
          <li>
            {{ $t('apartamentFacilitiesSPA[8]')}}
          </li>
          <li>
            {{ $t('apartamentFacilitiesSPA[9]')}}
          </li>
          <li>
            {{ $t('apartamentFacilitiesSPA[10]')}}
          </li>
          <li>
            {{ $t('apartamentFacilitiesSPA[11]')}}
          </li>
          <li>
            {{ $t('apartamentFacilitiesSPA[12]')}}
          </li>
          <li>
            {{ $t('apartamentFacilitiesSPA[13]')}}
          </li>
          <li>
            {{ $t('apartamentFacilitiesSPA[14]')}}
          </li>
          <li>
            {{ $t('apartamentFacilitiesSPA[15]')}}
          </li>
          <li>
            {{ $t('apartamentFacilitiesSPA[16]')}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>
<script scoped>
export default {
    name: "ApartFacilitiesListSPA",
}
</script>
<style scoped>
ul {
  list-style-type: circle;
}
li {
  font-size: small;

}
</style>