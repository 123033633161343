export default {
  data() {
      return {
         portfolioItems:  [
            {
                id: 30,
                img: require(`@/assets/images/aparts/AP_no_3/Villa_sol_oswiecim_app_three_salon.jpg`),
                title:'Villa Sol Oswiecim App Two - Salon',
            },
            {
                id: 31,
                img: require(`@/assets/images/aparts/AP_no_3/Villa_sol_oswiecim_app_three_salonB.jpg`),
                title:'Villa Sol Oswiecim App Two - Salon',
            },
            {
                id: 32,
                img: require(`@/assets/images/aparts/AP_no_3/Villa_sol_oswiecim_app_three_wejscie.jpg`),
                title:'Villa Sol Oswiecim App Two - Salon',
            },
            {
                id: 33,
                img: require(`@/assets/images/aparts/AP_no_3/Villa_sol_oswiecim_app_three_kuchniaB.jpg`),
                title:'Villa Sol Oswiecim App Two - Salon i kuchnia',
            },
            {
                id: 34,
                img: require(`@/assets/images/aparts/AP_no_3/Villa_sol_oswiecim_app_three_kuchnia.jpg`),
                title:'Villa Sol Oswiecim App Two - Kuchnia',
            },
            {
                id: 35,
                img: require(`@/assets/images/aparts/AP_no_3/Villa_sol_oswiecim_app_three_jadalnia.jpg`),
                title:'Villa Sol Oswiecim App Two - Łazienka',
            },
            {
                id: 36,
                img: require(`@/assets/images/aparts/AP_no_3/Villa_sol_oswiecim_app_three_lazienka.jpg`),
                title:'Villa Sol Oswiecim App Two - Sypialnia',
            },
         ]
      }
  }
}