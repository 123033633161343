export default {
  "main-home-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide professional service"])},
  "main-home-text-under-logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxury Apartments"])},
  "main-home-textHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Apartments for rent can be found around 10 minutes from Museum 'Auschwitz' and 10 minutes while taking a walk from Oświęcim market.They are very comfortable and you can also find there electric scooters, bikes , electric motor scooters , jaccuzi, Finnish bath.On the parking lot you can use charger for electric cars. "])},
  "main-home-button-resevation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book your apart"])},
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We warmly invite you to our newly created facility Villa Sol. Our villa offers five completely independent apartments, depending on your personal preferences. In one of them there are jacuzzi and sauna. The building is located near the railway station, on a quiet street in the Zasola estate. Thanks to the excellent location, you can take a walk to the Oświęcim market square as well as visit the Auschwitz-Birkenau Museum. In the area you can find the shopping mall, shops and public transport stops. For guests with children, the city playground, located about five minutes away on foot will be an attraction. We kindly invite you to use our apartments and all the amenities we offer"])},
  "nav-apartments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APARTMENTS"])},
  "nav-about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT US"])},
  "nav-near-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEAR TO US"])},
  "nav-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT"])},
  "close-tu-us-header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What can you see"])},
  "close-tu-us-header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close to our apartments"])},
  "close-tu-us-header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Few examples for you "])},
  "switcher-choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz język: "])},
  "contact-form-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "contact-form-lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "contact-form-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
  "contact-form-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
  "contact-form-write-message-area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND MESSAGE"])},
  "contact-our-localization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our adress : "])},
  "contact-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact :  "])},
  "contact-social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find us on : "])},
  "apartmentOneDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our apartments consist of a bathroom, bedroom and living room with a kitchenette, which is equipped, among others with a coffee machine, toaster and everything necessary to prepare various types of meals. Each apartments has free Wi-Fi internet access, TV in salon and bedroom . Our Villa Sol also provides parking spaces in the monitored parking lot located on the property."])},
  "apartamentOneGuestnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. of guests : "])},
  "apartamentOneSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size : "])},
  "apartamentOneBedroomQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedroom : "])},
  "apartamentFiveBed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["King Size bed : "])},
  "apartamentOneSofaQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofa for 2 p : "])},
  "apartamentOneBedQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofa for 2 p : "])},
  "apartamentFacilitiesSPA": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 TV"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caffe express"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dishwasher"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washing machine"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toaster"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableware for 4 people"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refrigerator"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water kettle"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitchen equipment"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WiFi"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pots"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Towels"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hair dryer"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 bathrobe"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosmetics"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaccuzi"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauna"])}
  ],
  "apartamentFacilities": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 TV"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caffe express"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dishwasher"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washing machine"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toaster"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableware for 4 people"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refrigerator"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water kettle"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitchen equipment"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WiFi"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pots"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Towels"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hair dryer"])}
  ],
  "apartmentFiveDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our SPA apartment consists of a bedroom with a kitchenette and in the hall there is a jacuzzi and a sauna at your disposal. The apartment is equipped with, among others, a coffee machine, a toaster and everything necessary to prepare various types of meals, free Wi-Fi internet access and a TV in the living room. Apartments Villa Sol Oświęcim also have parking spaces in a monitored car park located on the property. In our apartment, subject to availability, you can rent electric scooters and bicycles. You can also come by electric car because we have a car charger in the parking lot. The apartment can accommodate two adults, the price for two people is PLN 590 PLN with Sauna and Jacuzzi. Without Jacuzzi and Sauna price for the room is 290 PLN"])},
  "main-home-text-under-virtualtour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check our virtual tour on Villa Sol"])}
}