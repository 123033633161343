<template>
  <div id="Home">
    <div  class="BackgroundImage">
      <div class="content">
    <!-- VideoModal -->
    <!-- <VideoModal videoId="-iIFc0qD8Co" /> -->
      </div>
    </div>
      
      <!-- <div class="logo position-absolute top-0   start-50"> -->
      <div class="logo position-absolute top-0 start-50">
        
          <img
        src="../../assets/images/logosy/villaLogo.svg"
        alt="Logo apartamentów villa sol"
        class=""
        />
        <div class="textHeaderUnderLogo position-absolute">
        <h4>{{ $t("main-home-text-under-logo") }}</h4>
      </div> 
      </div>
      <a href="https://360.hash.com.pl/viewer/villasol" target="_blank">
        <div class="virtualtour position-absolute top-0 start-50">
          <h6>{{ $t("main-home-text-under-virtualtour") }}</h6>        
          <img
            src="../../assets/images/logosy/360-camera.png"
            class="logoVirtualTour"
            alt="Logo Virtual Tour"
          />
        </div>
      </a> 



      <div class="textHeader position-absolute" >
        <span title="Kilka słów na temat apartamentów"> {{ $t("main-home-textHeader") }}</span>
        <div class="reservation">
          <a href="https://www.booking.com/hotel/pl/villa-sol-oswiecim.pl.html?auth_success=1" target="_blank" alt="Link do rezerwacji apartamentu Villa Sol w Oświęcimiu">
            <button class="btn btn-light" alt="Button do rezerwacji">{{ $t("main-home-button-resevation")}}   
              <svg width="26" height="26" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path fill="#000" d="M459.15 269.75a133.197 133.197 0 0 1-55.862 179.975l-42.782 22.541-10.52 5.533a71.277 71.277 0 0 1-62.966 1.685l-167.077-71.38 15.733-46.676 99.363 19.194-51.458-97.78-82.843-157.411 40.357-21.232 82.844 157.457 19.934-10.485-36.521-69.445 40.335-21.22 36.52 69.445 19.935-10.485-28.2-53.598 40.358-21.232 28.2 53.598 19.945-10.576-19.354-36.886 40.346-21.174 19.354 36.885 54.348 103.301zM73.268 146.674a60.03 60.03 0 0 1 42.361-102.459 60.098 60.098 0 0 1 56.58 80.169l10.588 20.013A78.29 78.29 0 0 0 115.708 26a78.233 78.233 0 0 0-5.635 156.262L99.428 162.02a59.688 59.688 0 0 1-26.16-15.346z" />
              </svg>
            </button>
          </a>
        </div>  
      </div>

    
      <div class="copyright position-absolute">
        <a href="https://hash.com.pl">
          <p>Created by Hash s.c. © 2023</p>
        </a>
      </div>  

  <!-- <div class="position-relative" style="background-color: #d8bca4; color:aliceblue">
    <p>Nasze</p> <h1>Apartamenty Villa Sol</h1><p>w Oświęcimiu</p>
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
  </div>     -->
<!-- 
        <div class=" position-absolute " style="background-color: aqua;">
            <div class="row mw-100">
              <div class="col">
                1 of 3
              </div>
              <div class="col">
                2 of 3
              </div>
              <div class="col">
                3 of 3
              </div>
              <div class="col">
                4 of 3
              </div>
              <div class="col">
                5 of 3
              </div>
             </div>
        </div> -->
  </div>
</template>

<script>
// import VideoModal from "../common/Modal/VideoModal.vue";
import apartamentMainHome from "@/mixin/apartamentMainHome"

export default {
  name: "MainHome",
  mixins:[apartamentMainHome],
  components: {
    // VideoModal,
  },
};
</script>
<style scoped>
html, body {
  height: 100%;
  margin: 0;
  font: 400 15px/1.8 "Lato", sans-serif;
}
.container {
  display: flex;
  justify-content: center;
  margin-top: 20vh;
}
.logo {
  /* position: relative; */
  top: -90vh;
  width: 500px;
  height: 400px;
  margin-top: 20vh;
  margin-left: 10%;
  
}
.logo:after {
    display: block;
    color: #fff;
    letter-spacing: 20px;
    font-family: 'Poiret One', sans-serif;
    /* content: 'Luxury Apartments'; */
    font-size: 1em;
    text-align: center;
  }
  .virtualtour {
  /* position: relative; */
  /* top: -80vh; */
  text-align: center;
  width: 300px;
  height: 150px;
  margin-top: 20vh;
  margin-left: -40%;
  
}
.virtualTourText  {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: x-small;
}
.logoVirtualTour {
  width: 90px;
  margin: 10px;
  margin-left: 35%;
}

.content {
  height: 100vh;
}
.BackgroundImage {
  /* text-align:center; */
  background: url('../../assets/images/photos/backgroundMain/VillaSol_Oswiecim_apartament.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  /* -webkit-user-select: none; */
  /* -moz-user-select: none; */
  /* -ms-user-select: none; */
  -webkit-animation: zoomin 17s ease-in infinite;
  animation: zoomin 17s ease-in infinite;
  transition: all .1s ease-in-out;
  /* overflow: hidden; */
  /* z-index: 1; */
  
}
.textHeader {
  background-color: rgba(41, 40, 40, 0.1);
  border-radius: 7px;
  max-height: 50%;
  width: 294px;
  top: 38vh;
  font-size:1em;
  margin-left: 20vh;
  letter-spacing: 2px;
  text-align: justify;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.textHeader span {
  color: #fff;
}
.copyright {
 bottom: 5vh;
 margin-left: 10vh;
}
.copyright p {
  font-size: x-small;
}
.textHeaderUnderLogo {
  margin-top: -120px;
  letter-spacing: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.reservation {
  margin-inline: auto;
}
/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
  0% {transform: scale(1);}
  50% {transform: scale(1.05);}
  100% {transform: scale(1);}
}
@keyframes zoomin {
  0% {transform: scale(1);}
  50% {transform: scale(1.05);}
  100% {transform: scale(1);}
} /*End of Zoom in Keyframes */

/* Zoom out Keyframes */
@-webkit-keyframes zoomout {
  0% {transform: scale(1);}
  50% {transform: scale(1.05);}
  100% {transform: scale(1);}
}
@keyframes zoomout {
    0% {transform: scale(1);}
  50% {transform: scale(1.05);}
  100% {transform: scale(1);}
}/*End of Zoom out Keyframes */

@media only screen and (max-width: 900px) {
  .logo {
  position: relative;
  margin-left: -40%;
  margin-top: 40%;
  width: 200px;
  height: 120px;
}

.content {
  /* display: none; */
}
.logo:after {
  display: block;
  color: #fff;
  letter-spacing: 14px;
  font-family: 'Poiret One', sans-serif;
  /* content: 'Luxury Apartments'; */
  font-size: 1em;
  text-align: left;
  
}
.textHeader {
  background-color: rgba(41, 40, 40, 0.1);
  border-radius: 7px;
  height: 53lvh;
  width: 68%;
  top: 38vh;
  margin-left: 10vh;
  letter-spacing: 0px;
  text-align: justify;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.textHeader span {
  color: #fff;
}
.textHeaderUnderLogo {
  display: none;
}
/* .virtualtour {  
  width: 70px;
  height: 70px;
  margin-top: 3vh;
  margin-left: 15%;
  
} */
.virtualtour {
  /* position: relative; */
  /* top: -80vh; */
  text-align: center;
  width: 300px;
  height: auto;
  margin-top: 15vh;
  margin-left: -40%;
  
}
.logoVirtualTour {
  width: 60px;
  margin: 10px;
  margin-left: 75%;
}
}
</style>
