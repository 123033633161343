export default {
  "main-home-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapewniamy profesjonalną obsługę"])},
  "main-home-text-under-logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartmenty z klasą"])},
  "main-home-textHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasze apartamenty na wynajem, znajdują się 10 minut pieszo od wejścia do Muzeum 'Auschwitz' i 10 minut spacerem od starego rynku w Oświęcimiu. Są bardzo komfortowo wyposażone gdzie w miarę dostępności do mają państwo do dyspozycji elektryczne hulajnogi, rowery, skutery, jaccuzi i saunę. Na parkingu także znajduje się ładowarka do samochodów elektrycznych. "])},
  "main-home-button-resevation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezerwuj"])},
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilka słów o nas"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serdecznie zapraszamy do naszego nowo powstałego obiektu Villa Sol. Nasza villa oferuje pięć,  zupełnie niezależnych od siebie apartamentów, w zależności od Państwa osobistych preferencji. W jednym z nich  znajduje się jacuzzi wraz z sauną. Budynek położony jest niedaleko dworca PKP, przy spokojnej ulicy na Osiedlu Zasole. Dzięki doskonałej lokalizacji można pieszo dostać się na oświęcimski rynek jak również udać się na zwiedzanie Muzeum Auschwitz-Birkenau.  W pobliżu znajdują się galerie handlowe, sklepy a także przystanki komunikacji miejskiej. Dla gości z dziećmi atrakcją będzie oddalony o około pięć minut drogi pieszo miejski plac zabaw. Gorąco zapraszamy do korzystania z naszych apartamentów oraz wszystkich udogodnień, które mamy w ofercie."])},
  "nav-apartments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APARTMENTY"])},
  "nav-about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O NAS"])},
  "nav-near-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W POBLIŻU NAS"])},
  "nav-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTAKT"])},
  "close-tu-us-header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co zobaczyć"])},
  "close-tu-us-header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blisko naszego apartamentu"])},
  "close-tu-us-header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilka przykładów dla twojej wygody"])},
  "switcher-choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select lenguage: "])},
  "contact-form-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię"])},
  "contact-form-lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwisko"])},
  "contact-form-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój e-mail"])},
  "contact-form-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja wiadomość"])},
  "contact-form-write-message-area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WYŚLIJ WIADOMOŚĆ"])},
  "contact-our-localization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasz adres : "])},
  "contact-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt :  "])},
  "contact-social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdziesz nas na : "])},
  "apartmentOneDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasze apartamenty składają się z łazienki,  sypialni, salonu wraz z aneksem kuchennym, który jest wyposażony między innymi w ekspres do kawy, toster i wszystko co niezbędne do przygotowania rożnego rodzaju posiłków. Każdy z nich posiada bezpłatny dostęp do internetu Wi-Fi, telewizor w salonie i w sypialni. Apartamenty Villa Sol Oświęcim, posiadają także miejsca postojowe na monitorowanym parkingu znajdującym się na terenie posesji. W naszym apartamencie w miarę dostępności możesz wypożyczyć elektryczne hulajnogi i rowery. Możesz także przyjechać elektrycznym samochodem ponieważ posiadamy na parkingu ładowarkę do samochodów."])},
  "apartamentOneGuestnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba gości: "])},
  "apartamentOneSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozmiar : "])},
  "apartamentOneBedroomQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sypialnia : "])},
  "apartamentOneSofaQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofa 2-os. : "])},
  "apartamentOneBedQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łożka 1-os : "])},
  "apartamentFiveBed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łóżko King Size : "])},
  "apartamentFacilitiesSPA": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 telewizor"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expres do kawy"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmywarka"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pralka"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lodówka"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toster"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastawa dla 2 osób"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czajnik"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyposażenie kuchni"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WiFi"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naczynia"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ręczniki"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suszarka do włosów"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Szlafroki"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosmetyki"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaccuzi"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauna"])}
  ],
  "apartamentFacilities": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 telewizory"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekspress do kawy"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmywarka"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pralka"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lodówka"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toster"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastawa dla 4 osób"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czajnik"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyposażenie kuchni"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WiFi"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naczynia"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ręczniki"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suszarka do włosów"])}
  ],
  "apartmentFiveDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasz apartament SPA składa się z sypialni z aneksem kuchennym a w korytarzu znajduje się jacuzzi i sauna do państwa dyspozycji. Apartament jest wyposażony między innymi w ekspres do kawy, toster i wszystko co niezbędne do przygotowania rożnego rodzaju posiłków, bezpłatny dostęp do internetu Wi-Fi i telewizor w salonie. Apartamenty Villa Sol Oświęcim, posiadają także miejsca postojowe na monitorowanym parkingu znajdującym się na terenie posesji. W naszym apartamencie w miarę dostępności możesz wypożyczyć elektryczne hulajnogi i rowery. Możesz także przyjechać elektrycznym samochodem ponieważ posiadamy na parkingu ładowarkę do samochodów. Apartament pomieści dwie dorosłe osoby, cena za dwie osoby to 590 zł Sauna i Jaccuzi w cenie. Pokój bez sauny i jacuzzi w cenie 290 zł ."])},
  "main-home-text-under-virtualtour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz virtualny spacer po naszych apartamentach"])}
}