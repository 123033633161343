export default {
  data() {
      return {
         portfolioItems:  [
            {
                id: 24,
                img: require(`@/assets/images/photos/dworzec.jpg`),
                title:'ok 300 metrów od apartamentu / 3 min pieszo',
                subtitle:'STTATION_COSTAM',
            },
            {
                id: 24,
                img: require(`@/assets/images/photos/plac-zasole.jpg`),
                title:'ok 500 metrów od apartamentu / 5 min pieszo',
                subtitle:'Plac zabaw dla dzieci',
            },
            {
                id: 25,
                img: require(`@/assets/images/photos/zamek.jpg`),
                title:'ok 2 km od apartamentu / 15 minut spacerem',
                subtitle:'Zamek',
            },
            {
                id: 26,
                img: require(`@/assets/images/photos/auschwitz.jpg`),
                title:'10 minut pieszo',
                subtitle:'Muzeum Auschwitz',
            },
            {
                id: 27,
                img: require(`@/assets/images/photos/birkenau.jpg`),
                title:'20 minut spacerem',
                subtitle:'Muzeum Birkenau',
            },
            {
                id: 28,
                img: require(`@/assets/images/photos/energy.jpg`),
                title:'15 minut samochodem',
                subtitle:'Park Rozrywki Energylandia',
            },
            {
                id: 29,
                img: require(`@/assets/images/photos/rynek.jpg`),
                title:'10 minut pieszo',
                subtitle:'Rynek Główny',
            },
            {
                id: 30,
                img: require(`@/assets/images/photos/lodowisko.jpg`),
                title:'5 minut samochodem',
                subtitle:'Lodowisko',
            },
            {
                id: 31,
                img: require(`@/assets/images/photos/skatepark.jpg`),
                title:'5 minut samochodem',
                subtitle:'Skatepark',
            },
            {
                id: 32,
                img: require(`@/assets/images/photos/korty.jpg`),
                title:'5 minut pieszo',
                subtitle:'Korty tenisowe',
            },
         ]
      }
  }
}