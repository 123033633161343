export default {
  data() {
      return {
         portfolioItems:  [
            {
                id: 30,
                img: require(`@/assets/images/aparts/AP_no_5/Villa_sol_oswiecim_app_five_spa_jacuzziB.jpg`),
                title:'Villa Sol Oswiecim App Five SPA',
            },
            {
                id: 31,
                img: require(`@/assets/images/aparts/AP_no_5/Villa_sol_oswiecim_app_five_spa_jacuzzi.jpg`),
                title:'Villa Sol Oswiecim App Five SPA',
            },
            {
                id: 32,
                img: require(`@/assets/images/aparts/AP_no_5/Villa_sol_oswiecim_app_five_spa_jacuzzi_sauna.jpg`),
                title:'Villa Sol Oswiecim App Five SPA',
            },
            {
                id: 33,
                img: require(`@/assets/images/aparts/AP_no_5/Villa_sol_oswiecim_app_five_spa_sauna.jpg`),
                title:'Villa Sol Oswiecim App Five SPA',
            },
            {
                id: 34,
                img: require(`@/assets/images/aparts/AP_no_5/Villa_sol_oswiecim_app_five_spa_kuchnia.jpg`),
                title:'Villa Sol Oswiecim App Five SPA',
            },
            {
                id: 35,
                img: require(`@/assets/images/aparts/AP_no_5/Villa_sol_oswiecim_app_five_spa_sypialniaB.jpg`),
                title:'Villa Sol Oswiecim App Five SPA ',
            },
         ]
      }
  }
}