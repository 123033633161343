export default {
  data() {
      return {
         apartMainHomeItems:  [
            {
                id: 1,
                img: require(`@/assets/images/aparts/Budynek/VillaElSol_oswiecim_05.jpg`),
                title: "Villa Sol Oświęcim apartament widok z przodu",
            },
            {
                id: 2,
                img: require(`@/assets/images/aparts/Budynek/VillaElSol_oswiecim_06.jpg`),
                title: "VillaSol Oświęcim widok z przodu",
            },
            {
                id: 3,
                img: require(`@/assets/images/aparts/Budynek/VillaElSol_oswiecim_11.jpg`),
                title: "Villa Sol Oświęcim apartament widok z przodu i na ulice wewnetrzną",
            },
            {
                id: 4,
                img: require(`@/assets/images/aparts/Budynek/VillaElSol_oswiecim_10.jpg`),
                title: "Villa Sol Oświęcim Apartament nocny widok na budynek",
                alt: "Villa Sol Oświęcim Apartament nocny widok na budynek",
            },
            {
                id: 5,
                img: require(`@/assets/images/aparts/Budynek/VillaElSol_oswiecim_07.jpg`),
                title: "VillaSol Oświęcim Apartament nocny widok na budynek",
                alt: "VillaSol Oświęcim Apartament nocny widok na budynek",
            },
            {
                id: 6,
                img: require(`@/assets/images/aparts/Budynek/VillaElSol_oswiecim_01.jpg`),
                title: "Villa Sol Oświęcim Apartament widok na budynek",
                alt: "Villa Sol Oświęcim Apartament widok na budynek"
            },
            {
                id: 7,
                img: require(`@/assets/images/aparts/Budynek/VillaElSol_oswiecim_02.jpg`),
                title: "Villa Sol Oświęcim Apartament - widok na budynek",
                alt: "Villa Sol Oświęcim Apartament - widok na budynek"
            },
            {
                id: 8,
                img: require(`@/assets/images/aparts/Budynek/VillaElSol_oswiecim_03.jpg`),
                title: "Villa Sol Oświęcim apartament - ogród",
                alt: "Villa Sol Oświęcim apartament - ogród"
            },
            {
                id: 9,
                img: require(`@/assets/images/aparts/Budynek/VillaElSol_oswiecim_04.jpg`),
                title: "Villa Sol Oświęcim apartamenty - wejście",
                alt: "Villa Sol Oświęcim apartamenty - wejście"
            },
            {
                id: 10,
                img: require(`@/assets/images/aparts/Budynek/Villa_sol_oswiecim_altana_01.jpg`),
                title: "Villa Sol Oświęcim apartamenty - altana w dzień",
                alt: "Villa Sol Oświęcim apartamenty - altana w dzień"
            },
            {
                id: 11,
                img: require(`@/assets/images/aparts/Budynek/VillaElSol_oswiecim_08.jpg`),
                title: "Villa Sol Oświęcim Apartament - altana w nocy",
                alt: "Villa Sol Oświęcim Apartament - altana w nocy"
            },
            {
                id: 12,
                img: require(`@/assets/images/aparts/Budynek/Villa_sol_oswiecim_altana_02.jpg`),
                title: "VillaSol Oświęcim Apartament - altana w dzień",
                alt: "zdjęcie ogrodu"
            },
            {
                id: 13,
                img: require(`@/assets/images/aparts/Budynek/VillaElSol_oswiecim_09.jpg`),
                title: "Villa Sol Oświęcim Apartament - altana w nocy",
                alt: "Villa Sol Oświęcim Apartament - altana w nocy"
            },
            {
                id: 14,
                img: require(`@/assets/images/aparts/Budynek/Villa_sol_oswiecim_altana_03.jpg`),
                title: "Villa Sol Oświęcim Apartament - altana w dzień",
                alt: "Villa Sol Oświęcim Apartament - altana w dzień"
            },
            {
                id: 15,
                img: require(`@/assets/images/aparts/Budynek/Villa_sol_oswiecim_hulajnogi_rowery.jpg`),
                title: "Villa Sol Oświęcim Apartament - Hulajnogi i rowery do dyspozycji gości",
                alt: "Villa Sol Oświęcim Apartament - Hulajnogi i rowery do dyspozycji gości"
            },
            {
                id: 16,
                img: require(`@/assets/images/aparts/Budynek/Villa_sol_oswiecim_ladowarka_do_samochodu_elzab.jpg`),
                title: "Villa Sol Oświęcim Apartament - Ładowarka do samochodu elektrycznego",
                alt: "Villa Sol Oświęcim Apartament - Ładowarka do samochodu elektrycznego"
            },
         ]
      }
  }
}