export default {
  data() {
      return {
         portfolioItems:  [
            {
                id: 30,
                img: require(`@/assets/images/aparts/AP_no_1/Villa_sol_oswiecim_app_one_salon_TV.jpg`),
                title:'Villa Sol Oswiecim App One - Salon TV',
            },
            {
                id: 31,
                img: require(`@/assets/images/aparts/AP_no_1/Villa_sol_oswiecim_app_one_kuchnia_salon.jpg`),
                title:'Villa Sol Oswiecim App One - Salon TV',
            },
            {
                id: 32,
                img: require(`@/assets/images/aparts/AP_no_1/Villa_sol_oswiecim_app_one_salon_i_kuchnia.jpg`),
                title:'Villa Sol Oswiecim App One - Salon TV',
            },
            {
                id: 33,
                img: require(`@/assets/images/aparts/AP_no_1/Villa_sol_oswiecim_app_one_salon_kuchnia.jpg`),
                title:'Villa Sol Oswiecim App One - Salon TV',
            },
            {
                id: 34,
                img: require(`@/assets/images/aparts/AP_no_1/Villa_sol_oswiecim_app_one_TV_salon.jpg`),
                title:'Villa Sol Oswiecim App One - Salon TV',
            },
            {
                id: 35,
                img: require(`@/assets/images/aparts/AP_no_1/Villa_sol_oswiecim_app_one_sypialnia.jpg`),
                title:'Villa Sol Oswiecim App One - Salon TV',
            },
            {
                id: 36,
                img: require(`@/assets/images/aparts/AP_no_1/Villa_sol_oswiecim_app_one_sypialnia_2.jpg`),
                title:'Villa Sol Oswiecim App One - Salon TV',
            },
            {
                id: 37,
                img: require(`@/assets/images/aparts/AP_no_1/Villa_sol_oswiecim_app_one_łazienka.jpg`),
                title:'Villa Sol Oswiecim App One - Salon TV',
            },
            {
                id: 38,
                img: require(`@/assets/images/aparts/AP_no_1/Villa_sol_oswiecim_app_one_wejscie.jpg`),
                title:'Villa Sol Oswiecim App One - Salon TV',
            },
         ]
      }
  }
}